<template>
<div>

    <s-crud :rowDefault="false" noFull :height='$vuetify.breakpoint.xs?"auto":"200"' :config="configCrud" :filter="filter" searchInput title="Aprobación de Requerimientos" :single-row="this.isMobile" @rowSelected="rowSelected($event)" ref="sCrudApproval">

        <template v-slot:SecStatus="{ row }">
            <v-icon small class="mr-2" @click="viewDetail(row)">
                {{ iconmobil }}
            </v-icon>
        </template>
        <template v-if="!this.isMobile" v-slot:options>
            <v-tooltip>
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on" :disabled="selected.length == 0" small elevation="0" @click="AddMasive(true)">
                        <v-icon style="font-size:16px;" color="success">far fa-thumbs-up</v-icon>
                    </v-btn>
                </template>
                <span>Aprobacion Masivas</span>
            </v-tooltip>
            <v-tooltip>
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on" :disabled="selected.length == 0" small elevation="0" @click="DenyMasive(true)">
                        <v-icon style="font-size:16px;" color="error">far fa-thumbs-down</v-icon>
                    </v-btn>
                </template>
                <span>Desaprobar Masivas</span>
            </v-tooltip>
        </template>

        <template v-slot:ReqAttach1="{row}">
            <v-btn v-if="row.ReqAttach1 !== null && row.ReqAttach1.length > 0" color="success" class="mt-0" icon x-small @click="openFile({name: row.ReqAttach1})">
                <v-icon>mdi-download</v-icon>
            </v-btn>
            {{row.ReqAttach1}}
        </template>

        <template v-slot:TypePriorityName="{ row }">
            <v-chip x-small :color="row.TypePriorityColor">
                {{ row.TypePriorityName }}
            </v-chip>
        </template>
    </s-crud>

    <v-col style="padding: 0px; margin-top: 6px" v-if="!this.isMobile">
        <s-toolbar :label="cabecera"></s-toolbar>
        <v-data-table :items-per-page="-1" hide-default-footer dense :items="itemsDetail" :headers="headersDetail" item-key="name">
            <template v-slot:item.TypeRequirement="{ item }">
                <v-chip x-small :color="item.TypeRequirement == 1 ? 'info' : 'success'">
                    {{ item.TypeRequirement == 1 ? 'Compra' : 'Servicio' }}
                </v-chip>
            </template>

        </v-data-table>
        <!-- <v-data-table hide-default-footer :items-per-page="-1" dense :items="itemsDetail" :headers="headersOrder" /> -->
    </v-col>

    <v-dialog v-model="dialogDelete" max-width="500px" persistent>
        <v-card>
            <v-card-title class="headline">Esta seguro de Rechazar?</v-card-title>
            <v-card-text>
                <s-textarea autofocus label="Motivo de Rechazo" v-model="AtsObservation" />
            </v-card-text>
            <v-card-actions>
                <v-btn color="red darken-1" text @click="closeDelete">Cancelar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" dark @click="denyItemConfirm()">confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- Cuaando es mobile -->
    <v-dialog v-model="dialogViewRequeriment" persistent max-width="600px">
        <v-card elevation="2">
            <v-toolbar>
                <v-toolbar-title>Requerimiento </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogViewRequeriment = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-list>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-icon class="grey lighten-1" dark>
                            far fa-clipboard
                        </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title v-text="this.detail.ReqDocumentNumber"></v-list-item-title>

                        <v-list-item-subtitle>N°Requerimiento</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item>
                    <v-list-item-avatar>
                        <v-icon class="grey lighten-1" dark>
                            far fa-calendar-alt
                        </v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title v-text="$fun.formatDateView(this.detail.SecCreate)"></v-list-item-title>

                        <v-list-item-subtitle>F.Creacion</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </v-list>

            <v-list three-line>
                <v-subheader style="height: 6px;font-weight: bold;">Listado de Items</v-subheader>
                <v-list-item-group v-model="selected">
                    <template v-for="(item, index) in this.detail.Detail">
                        <v-list-item :key="index" style="min-height: 0.5px;">
                            <template>
                                <v-list-item-content style="align-items: flex-start;">
                                    <v-list-item-subtitle class="text--primary" v-text="item.DerDescription"></v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-list-item-action-text></v-list-item-action-text>
                                    {{ item.DerQuantity }}
                                    {{ item.UnitMeasurement }}
                                </v-list-item-action>
                            </template>
                        </v-list-item>

                        <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
                    </template>
                </v-list-item-group>
            </v-list>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" dark @click="denyItem($event)" style="width: 90px;">Desaprobar</v-btn>
                <v-btn color="blue darken-1" dark @click="approvalMobil($event)" style="width: 90px;">APROBAR</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
        <v-card color="primary" dark>
            <v-card-text v-if="messageProcessing.length == 0">
                Por favor espere
                <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
            </v-card-text>
            <v-card-text v-else>
                <div class="text--white pt-4">
                    Error al realizar busqueda <br />
                    {{ messageProcessing }}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>

</div>
</template>

<script>
import _sNotification from "@/services/Logistics/RequirementApproval.js";
import _sRequirement from "@/services/Logistics/RequirementService";
import _sService from "@/services/Logistics/ApprovalNotification.js";
import _sGeneral from "@/services/HelperService.js";

export default {
    components: {},
    props: {},
    data: () => ({

        processing: false,
        messageProcessing: "",

        filter: {
            UsrID: 0,
            PstID: 0
        },
        dialogDelete: false,
        dialogViewRequeriment: false,
        dialogViewOrder: false,
        dialogOrder: false,
        filterDetail: {
            ReqID: 0,
        },
        selected: {},
        reqID: 0,
        items: [],
        requeriment: [],
        detail: [],
        AtsObservation: "",
        itemsDetail: [],
        headersDetail: [{
                text: "Tipo Req",
                value: "TypeRequirement",
                width: "50"
            },
            {
                text: "ArticuloID",
                value: "ArtCode",
                sortable: true,
                width: 100
            },
            {
                text: "Descripción",
                value: "DerDescription",
                width: 300

            },
            {
                text: "Comentario",
                value: "DerObservation",
                sortable: false,
                width: 100
            },
            {
                text: "Unid. Medida",
                value: "UnitMeasurement",
                sortable: false,
                width: 200,
                align: "center",
            },
            {
                text: "Cantidad",
                value: "DerQuantity",
                sortable: false,
                align: "center",
                width: 100,
            },

        ],
        cabecera: "Detalle",
        isMasive: false,
        denyitem: {},
        requerimentMobil: {},
        isDeny: false,
        denyMobil: {},
        iconmobil: "far fa-envelope",
        ReqAttach1: null
    }),
    watch: {
        dialogDelete(val) {
            val || this.closeDelete();
        },
        filterDetail() {
            return {
                ReqID: this.reqID
            };
        },
        dialogViewOrder() {
            if (this.dialogViewOrder == false) this.iconmobil = "far fa-envelope";
        },
        dialogViewRequeriment() {
            if (this.dialogViewRequeriment == false) this.iconmobil = "far fa-envelope";
        },
    },
    created() {
        this.filter.UsrID = this.$fun.getUserID();
        this.filter.PstID = this.$fun.getUserInfo().CtrContract.PstID;
        //this.filter.TypeRequirement = 1;
    },
    computed: {
        configCrud() {
            return {
                model: {
                    AtsID: "ID",
                    ReqDate: "date",
                    AleStatus: "int",
                    AtsStatus: "",
                    SecCreate: "date",
                    SecStatus: "int",
                    ReqAttach1: "",
                    TypePriorityName: "",
                    TypeRequirement: ""
                },
                service: _sNotification,
                headers: this.isMobile ? [{
                        text: "",
                        value: "SecStatus",
                        align: "center",
                        width: "5"
                    },
                    {
                        text: "N°Req",
                        value: "ReqDocumentNumber"
                    },
                    {
                        text: "Fecha",
                        value: "ReqDate"
                    },
                    {
                        text: "Usuario",
                        value: "UsrName"
                    },
                ] : [
                    /* {
                        text: "Empresa",
                        value: "nameBussines",
                        width: "180"
                    }, */
                    {
                        text: "N°Req",
                        value: "ReqDocumentNumber",
                        width: "180"
                    },
                    {
                        text: "Fecha",
                        value: "ReqDate",
                        width: "100",
                        sorteable: true
                    },
                    {
                        text: "Usuario",
                        value: "UsrName",
                        
                    },
                    {
                        text: "Área",
                        value: "AreName",
                        sorteable: true
                    },
                    /* {
                        text: "Par. Gasto",
                        value: "SptID",
                        sorteable: true
                    }, */
                    {
                        text: "Prioridad",
                        value: "TypePriorityName",
                        sorteable: true,
                        align: "center",
                        width: 70
                    },
                    {
                        text: "Anexo 01",
                        value: "ReqAttach1"
                    },
                    /* {
                        text: "Observacion",
                        value: "ReqObservation"
                    }, */
                ],
            };
        },
        isMobile() {
            return this.$vuetify.breakpoint.xs;
        },
    },
    methods: {

        openFile(url) {
            if (url == null) return;
            _sGeneral.download(url.name, this.$fun.getUserID(), 1).then((r) => {
                this.$fun.download(r.data, url.name);
            });
        },

        //Al seleccionar item de Grilla
        rowSelected(item) {
            this.selected = item;

            console.log(this.selected)
            if (item.length > 0) {
                this.reqID = item[item.length - 1].ReqID;
            } else {
                this.reqID = 0;
                this.itemsDetail = [];
                this.cabecera = "Detalle";
            }
            if (this.reqID != 0) this.getdataGeneral(this.reqID);
        },

        //Trae Detalle del Requerimiento
        getdataGeneral(id) {

            this.requeriment = {
                ReqID: id
            };

            _sRequirement.byID(this.requeriment, this.$fun.getUserID()).then((r) => {
                if (r.status == 200) {
                    this.detail = {
                        ...r.data
                    };
                    this.itemsDetail = this.detail.Detail;

                    console.log("aaaaaa", this.itemsDetail)
                    this.cabecera = "Detalle de Requerimiento " + this.detail.ReqDocumentNumber;
                }
            });

        },

        getdata(id) {

            this.dialogViewRequeriment = true;
            this.dialogViewOrder = false;
            this.requeriment.ReqID = id;
            // Requerimiento
            _sRequirement.byID(this.requeriment, this.$fun.getUserID()).then((r) => {
                if (r.status == 200) {
                    this.detail = {
                        ...r.data
                    };
                    this.detail.SecCreate = this.moment(this.detail.SecCreate, this.$const.FormatDateDB).format(this.$const.FormatDateView);
                }
            });

        },

        //mostrar detalle
        viewDetail(item) {
            this.requerimentMobil = item;
            this.iconmobil = "far fa-envelope-open";
            this.isDeny = true;
            this.denyMobil = item;
            this.getdata(item.ReqID);
        },

        denyItem(item) {
            if (item != null) {
                this.dialogDelete = true;
                this.isMasive = false;
                this.AtsObservation = "";
                this.denyitem = item;
                this.isDeny = this.isMobile;
            }
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        denyItemConfirm(item) {
            if (this.isMasive) {
                this.selected.forEach((element) => {
                    element.DocumentNumber = element.ReqDocumentNumber;
                    element.DocEntry = element.ReqID;
                    element.TypeApproval = 1;
                    element.UsrCreateID = this.$fun.getUserID();
                    element.AtsStatus = 3;
                    element.AtsObservation = this.AtsObservation;
                });
                this.save(this.selected, 2);
            } else {
                if (this.isDeny) {
                    this.denyMobil.DocumentNumber = this.denyMobil.ReqDocumentNumber;
                    this.denyMobil.DocEntry = this.denyMobil.ReqID;
                    this.denyMobil.TypeApproval = 1;
                    this.denyMobil.AtsStatus = 3;
                    this.denyMobil.UsrCreateID = this.$fun.getUserID();
                    this.denyMobil.AtsObservation = this.AtsObservation;
                    this.items.push(this.denyMobil);
                } else {
                    this.denyitem.DocumentNumber = this.denyitem.ReqDocumentNumber;
                    this.denyitem.DocEntry = this.denyitem.ReqID;
                    this.denyitem.TypeApproval = 1;
                    this.denyitem.AtsStatus = 3;
                    this.denyitem.UsrCreateID = this.$fun.getUserID();
                    this.denyitem.AtsObservation = this.AtsObservation;
                    this.items.push(this.denyitem);
                }
                this.save(this.items, 2);
            }
            this.closeDelete();
        },
        refresh() {
            this.items = [];
            this.dialogViewRequeriment = false;
            this.dialogViewOrder = false;
            this.$refs.sCrudApproval.refresh();
        },
        AddMasive(item) {
            this.selected.forEach((element) => {
                element.AtsStatus = 2;
                element.DocumentNumber = element.ReqDocumentNumber;
                element.DocEntry = element.ReqID;
                element.TypeApproval = 1;
                element.UsrCreateID = this.$fun.getUserID();
            });
            this.save(this.selected, 1);
        },
        DenyMasive(item) {
            this.AtsObservation = "";
            this.dialogDelete = true;
            this.isMasive = true;
        },
        save(item, transaction) {

            this.messageProcessing = ""
            this.$fun.alert('Confirmar Operación?', 'question', false).then((r) => {
                if (r.value) {
                    this.processing = true;
                    var message;
                    if (transaction == 1) {
                        message = "Guardado Correctamente";
                    } else {
                        message = "Actualizado Correctamente";
                    }

                    _sService.save(item, this.$fun.getUserID()).then((r) => {
                            this.processing = false
                            this.$fun.alert(message, "success");
                            this.refresh();
                        },
                        (e) => {
                            this.messageProcessing = e.response.data.Message;
                            this.processing = true;
                        });
                }
            });

        },

        approvalItem(item) {
            item.AtsStatus = 2;

            this.items.push(item);
            this.save(this.items, 1);
        },
        approvalMobil() {
            this.items = [];
            this.requerimentMobil.DocumentNumber = this.requerimentMobil.ReqDocumentNumber;
            this.requerimentMobil.DocEntry = this.requerimentMobil.ReqID;
            this.requerimentMobil.TypeApproval = 1;
            this.requerimentMobil.AtsStatus = 2;
            this.items.push(this.requerimentMobil);
            this.save(this.items, 1);
        },
    },
};
</script>
